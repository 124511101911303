import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >



<path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m5555 2851 c471 -90 869 -297 1196 -621 82 -81 116 -120 214 -242 48
-59 145 -217 204 -330 160 -307 237 -610 248 -966 15 -495 -120 -949 -410
-1369 -27 -40 -53 -73 -57 -73 -4 0 -24 7 -43 15 l-36 15 84 125 c183 270 304
579 356 905 21 128 18 548 -4 665 -48 251 -122 473 -223 661 -42 81 -162 269
-186 296 -7 7 -26 31 -43 53 -50 69 -246 264 -335 334 -436 346 -960 509
-1509 470 -399 -28 -747 -149 -1085 -379 -162 -110 -370 -306 -476 -450 -23
-30 -49 -64 -58 -75 -9 -11 -37 -54 -62 -95 -353 -570 -433 -1238 -223 -1860
84 -249 253 -544 415 -724 22 -24 17 -32 -29 -47 -36 -12 -38 -11 -71 27 -19
21 -60 75 -92 120 -459 643 -554 1485 -247 2199 78 183 194 381 305 520 88
111 308 325 418 407 278 206 604 350 934 412 187 35 176 34 455 31 205 -2 270
-7 360 -24z m-105 -156 c555 -84 1034 -364 1378 -807 160 -207 296 -489 366
-763 72 -280 76 -647 12 -950 -52 -243 -167 -512 -311 -723 -104 -154 -96
-147 -139 -132 -20 6 -36 16 -36 22 0 5 20 35 44 66 199 256 347 615 391 950
17 127 19 355 4 490 -38 356 -196 741 -420 1022 -65 82 -214 234 -299 305
-206 171 -516 329 -778 395 -195 49 -246 54 -507 54 -232 -1 -259 -3 -378 -27
-407 -86 -749 -269 -1038 -557 -672 -671 -781 -1725 -259 -2520 62 -94 115
-163 177 -230 18 -19 33 -38 33 -42 0 -4 -19 -13 -41 -20 -48 -14 -37 -23
-148 122 -275 357 -431 813 -431 1262 0 260 42 499 130 739 60 162 205 426
297 540 144 179 302 329 474 451 110 79 318 188 464 243 97 36 270 81 400 104
145 25 465 28 615 6z m170 -419 c0 -2 -11 -6 -25 -8 -13 -3 -22 -1 -19 3 5 9
44 13 44 5z m-549 -147 c-17 -18 -31 -39 -31 -45 0 -7 -7 -14 -15 -18 -12 -4
-12 0 2 22 24 36 65 83 70 78 2 -2 -10 -19 -26 -37z m-71 -94 c-7 -9 -15 -13
-19 -10 -3 3 1 10 9 15 21 14 24 12 10 -5z m-31 -41 c-15 -18 -22 -13 -13 10
3 9 10 13 16 10 5 -3 4 -12 -3 -20z m-85 -106 c-33 -40 -71 -89 -86 -108 -14
-19 -38 -48 -52 -63 -15 -16 -45 -54 -68 -85 -23 -32 -48 -63 -55 -70 -7 -7
-34 -41 -60 -75 -26 -34 -50 -64 -53 -67 -4 -3 -31 -36 -60 -75 -68 -88 -102
-126 -108 -121 -2 3 10 19 26 38 17 18 47 56 68 83 21 28 60 77 87 110 60 74
58 71 137 175 36 47 72 92 81 100 9 8 31 35 49 60 19 25 48 61 65 80 16 20 41
50 54 68 13 18 26 30 29 27 3 -2 -22 -37 -54 -77z m949 -45 c-7 -2 -19 -2 -25
0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-236 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m-70 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m-121 -29 c-11 -8 -27 -15 -35 -15 -10 1 -7 6 9 15 33 19 50 18 26
0z m-72 -30 c-10 -8 -25 -15 -32 -15 -7 0 -15 -7 -18 -15 -4 -8 -14 -15 -23
-15 -9 0 -30 -12 -46 -27 -17 -14 -41 -36 -55 -47 -23 -20 -24 -20 -10 -1 8
11 22 25 30 31 8 7 26 21 39 33 13 12 30 21 37 21 7 0 17 6 21 13 10 16 44 36
62 36 10 1 9 -3 -5 -14z m513 -101 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m-720 -59 c-4 -8 -11 -15 -17 -15 -6 0 -6 7 2 20 14 22 24 19 15
-5z m824 -2 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m-851
-32 c0 -5 -7 -12 -16 -15 -14 -5 -15 -4 -4 9 14 17 20 19 20 6z m910 -23 c0
-12 -6 -9 -26 13 -19 22 -19 22 4 8 12 -8 22 -17 22 -21z m-950 -33 c-7 -9
-15 -13 -17 -11 -6 6 20 36 26 30 2 -2 -2 -10 -9 -19z m990 -26 c12 -22 20
-45 18 -49 -2 -5 -15 13 -29 40 -15 28 -23 50 -18 50 5 0 18 -18 29 -41z
m-1024 -41 c-19 -27 -21 -19 -4 18 7 14 14 21 16 15 2 -6 -4 -21 -12 -33z
m1064 -95 c0 -12 5 -25 10 -28 6 -3 10 -11 10 -16 0 -21 -27 17 -33 46 -3 17
-8 39 -12 50 -4 14 -2 13 9 -5 8 -14 15 -35 16 -47z m-1095 7 c-4 -12 -9 -19
-12 -17 -3 3 -2 15 2 27 4 12 9 19 12 17 3 -3 2 -15 -2 -27z m-28 -127 c-3
-10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-646 -50 c-42 -57 -61 -78
-61 -68 0 6 14 25 30 43 17 18 30 37 30 43 0 5 5 9 11 9 6 0 1 -12 -10 -27z
m646 -95 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-717 3 c0 -5
-7 -12 -16 -15 -14 -5 -15 -4 -4 9 14 17 20 19 20 6z m1010 -23 c0 -6 -6 -5
-15 2 -8 7 -15 14 -15 16 0 2 7 1 15 -2 8 -4 15 -11 15 -16z m-1040 -12 c0 -2
-8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m780 -99 c0 -5 -4 -5
-10 -2 -5 3 -10 14 -10 23 0 15 2 15 10 2 5 -8 10 -19 10 -23z m326 1 c29 -31
29 -31 2 -12 -15 10 -29 25 -32 32 -8 18 -2 15 30 -20z m-715 5 c13 -16 12
-17 -3 -4 -17 13 -22 21 -14 21 2 0 10 -8 17 -17z m429 -97 c0 -5 -7 -3 -15 4
-8 7 -15 20 -15 28 0 13 3 12 15 -4 8 -10 15 -23 15 -28z m-349 7 c13 -16 12
-17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m710 0 c13 -16 12 -17 -3 -4
-10 7 -18 15 -18 17 0 8 8 3 21 -13z m74 -73 c27 -27 47 -50 44 -50 -3 0 -29
23 -57 50 -28 28 -48 50 -44 50 4 0 30 -22 57 -50z m-1104 -7 c13 -16 12 -17
-3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m730 -25 c15 -28 5 -21 -21 15 -15
20 -17 27 -6 18 9 -8 22 -23 27 -33z m-701 -8 c0 -5 -2 -10 -4 -10 -3 0 -8 5
-11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-232 -55 c-3 -3 -9 2 -12 12 -6 14
-5 15 5 6 7 -7 10 -15 7 -18z m1387 15 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10
0 6 2 10 4 10 3 0 8 -4 11 -10z m69 -77 c46 -54 26 -46 -24 9 -24 28 -35 45
-25 38 11 -7 33 -28 49 -47z m-413 0 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18
17 0 8 8 3 21 -13z m-926 -51 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9
12 -12z m675 3 c19 -22 8 -25 -12 -3 -10 10 -14 18 -9 18 5 0 14 -7 21 -15z
m-636 -41 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m1441 -64
c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-1335
-50 c8 -5 11 -10 5 -10 -5 0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z
m985 -8 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m390 8 c3 -6
-1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m-1085 -10 c0 -5 -2 -10 -4
-10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-230 -62 c0 -4 -9 1
-20 12 -11 11 -20 23 -20 27 0 3 9 -2 20 -12 11 -10 20 -22 20 -27z m40 -38
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m-375 -30 c10 -11 16 -20 13 -20 -3 0 -13 9 -23 20 -10 11 -16 20 -13 20 3 0
13 -9 23 -20z m415 -10 c11 -11 16 -20 12 -20 -5 0 -17 9 -27 20 -10 11 -15
20 -12 20 4 0 16 -9 27 -20z m685 -18 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10
-3 15 -9 12 -12z m749 -38 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14
-7z m-1359 -24 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4
11 -10z m1486 -117 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z
m33 -39 c5 -14 4 -15 -9 -4 -17 14 -19 20 -6 20 5 0 12 -7 15 -16z m-346 -59
c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m-1824 -11 l26 -27 0
-456 c0 -434 -1 -458 -20 -488 -13 -23 -29 -34 -55 -39 -48 -9 -99 2 -121 27
-18 20 -19 46 -22 488 l-2 466 22 27 c20 25 30 28 84 28 53 0 65 -4 88 -26z
m860 -3 c-2 -3 -12 3 -22 13 -16 17 -16 18 5 5 12 -7 20 -15 17 -18z m-366
-46 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m432 -25 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m1330 -25 c0
-5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m-635 5
c16 -17 16 -20 3 -15 -9 4 -19 13 -22 21 -7 19 -2 18 19 -6z m-660 -10 c3 -6
-1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m270 0 c3 -6 -1 -7 -9 -4
-18 7 -21 14 -7 14 6 0 13 -4 16 -10z m-661 -16 c3 -8 2 -12 -4 -9 -6 3 -10
10 -10 16 0 14 7 11 14 -7z m-345 -29 c16 -8 31 -22 35 -31 11 -30 8 -772 -4
-794 -14 -25 -79 -44 -124 -36 -20 4 -44 18 -56 33 -19 25 -20 38 -20 408 l0
383 31 26 c35 30 93 35 138 11z m1052 -2 c13 -16 12 -17 -3 -4 -17 13 -22 21
-14 21 2 0 10 -8 17 -17z m-266 -3 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6
2 10 4 10 3 0 8 -4 11 -10z m1275 -25 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0
6 5 3 10 -5 5 -8 10 -19 10 -25z m-302 0 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6
7 -7 10 -15 7 -18z m-934 -22 c10 -25 3 -23 -17 3 -13 18 -14 22 -3 18 8 -3
17 -13 20 -21z m661 9 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z
m-1061 -8 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m1365 -34
c0 -10 -3 -8 -9 5 -12 27 -12 43 0 25 5 -8 9 -22 9 -30z m-924 -10 c10 -11 16
-20 13 -20 -3 0 -13 9 -23 20 -10 11 -16 20 -13 20 3 0 13 -9 23 -20z m942
-32 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-627 -8 c6 -11 8
-20 6 -20 -3 0 -10 9 -16 20 -6 11 -8 20 -6 20 3 0 10 -9 16 -20z m-266 -16
c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m676 1 c20 -24 6 -28
-15 -5 -10 11 -13 20 -8 20 6 0 16 -7 23 -15z m-1326 -26 l26 -20 0 -303 c0
-290 -1 -304 -20 -329 -12 -15 -36 -29 -56 -33 -45 -8 -110 11 -124 36 -6 11
-10 135 -10 321 0 297 0 303 22 326 30 32 123 33 162 2z m320 -55 c3 -8 2 -12
-4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m726 -39 c14 -19 14 -19 -2 -6 -10
7 -18 18 -18 24 0 6 1 8 3 6 1 -2 9 -13 17 -24z m435 -29 l20 -18 -20 10 c-11
6 -29 21 -40 33 -20 24 -20 24 0 9 11 -8 29 -23 40 -34z m-1110 16 c-3 -3 -11
0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m400 8 c3 -5 1 -10 -4 -10 -6 0
-11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m802 -12 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m-1173 -24 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10
16 0 14 7 11 14 -7z m1204 -19 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10
-15 7 -18z m-1357 -14 l29 -29 0 -215 c0 -201 -1 -216 -20 -240 -41 -52 -147
-48 -181 6 -18 30 -19 47 -17 245 l3 214 28 24 c43 37 118 34 158 -5z m1274
19 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m-665 -42 c0 -6 -6 -5 -15 2 -8 7 -15 14 -15 16 0 2 7 1 15 -2 8 -4 15 -11 15
-16z m700 12 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4
11 -10z m35 -30 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z
m-1107 -25 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m400 0 c-3
-3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m847 -3 c-3 -3 -11 0 -18
7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m-810 -22 c3 -6 -1 -7 -9 -4 -18 7 -21
14 -7 14 6 0 13 -4 16 -10z m745 0 c8 -5 11 -10 5 -10 -5 0 -17 5 -25 10 -8 5
-10 10 -5 10 6 0 17 -5 25 -10z m-699 -47 c13 -16 12 -17 -3 -4 -10 7 -18 15
-18 17 0 8 8 3 21 -13z m774 -3 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2
10 4 10 5 0 13 -4 16 -10z m65 -40 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m-725 -20 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14
6 0 13 -4 16 -10z m808 -20 c29 -16 45 -30 37 -30 -9 0 -20 4 -26 10 -5 5 -27
19 -49 29 -22 11 -34 20 -27 21 7 0 36 -13 65 -30z m-553 10 c-8 -5 -19 -10
-25 -10 -5 0 -3 5 5 10 8 5 20 10 25 10 6 0 3 -5 -5 -10z m735 -90 c19 -8 19
-9 -2 -9 -12 -1 -25 4 -28 9 -7 12 2 12 30 0z m118 -37 c-7 -2 -19 -2 -25 0
-7 3 -2 5 12 5 14 0 19 -2 13 -5z m80 -20 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5
14 0 19 -2 13 -5z m-1494 -329 c39 -11 56 -12 66 -4 7 6 37 10 67 8 l53 -3 3
-92 3 -92 -63 -3 -63 -3 41 -22 c91 -48 120 -133 74 -208 -41 -66 -163 -94
-247 -55 -37 16 -44 17 -51 4 -6 -11 -24 -14 -73 -12 -35 2 -63 6 -61 11 1 4
2 45 2 92 l0 85 63 0 62 0 -43 26 c-52 31 -72 68 -72 134 0 43 5 55 33 86 31
34 91 63 133 64 11 0 44 -7 73 -16z m859 -6 c26 -10 35 -10 41 0 5 9 27 12 67
10 l59 -3 3 -92 3 -92 -68 -3 -68 -3 49 -25 c61 -31 87 -64 93 -121 6 -53 -22
-101 -77 -133 -46 -28 -163 -30 -200 -3 -30 21 -40 22 -40 2 0 -12 -14 -15
-70 -15 l-71 0 3 93 3 92 65 6 c36 3 57 6 48 7 -30 3 -87 62 -99 102 -16 54
-4 104 35 143 55 55 133 67 224 35z m-2600 -44 c2 -43 0 -52 -15 -56 -20 -5
-31 -58 -12 -58 21 0 68 47 58 57 -6 6 -9 32 -7 59 l3 49 115 0 115 0 3 -52
c3 -49 1 -53 -20 -53 -24 0 -98 -60 -98 -79 0 -6 25 -51 56 -100 38 -63 62
-91 77 -93 19 -3 22 -10 25 -55 l3 -53 -140 0 -141 0 0 50 c0 36 4 52 15 56
13 5 12 12 -7 45 -12 21 -25 39 -29 39 -13 0 -32 -30 -35 -55 -2 -19 2 -25 17
-25 16 0 19 -8 19 -55 l0 -55 -115 0 -115 0 0 51 c0 42 3 52 20 56 19 5 20 14
20 135 0 121 -1 130 -20 135 -16 4 -20 14 -20 52 0 26 3 51 7 55 4 3 54 5 112
4 l106 -3 3 -51z m740 -32 l3 -83 -68 3 c-65 3 -68 4 -68 28 0 22 -4 25 -40
25 -38 0 -40 -2 -43 -32 -3 -31 -1 -33 30 -35 18 0 44 0 57 1 31 3 41 -13 41
-66 l0 -43 -65 0 -65 0 0 -45 0 -45 46 0 c44 0 45 1 42 28 -3 27 -3 27 65 30
l67 3 2 -78 c1 -49 3 -62 5 -34 3 32 9 44 24 48 18 5 19 15 19 139 0 128 -1
134 -21 134 -18 0 -20 5 -17 53 l3 52 100 2 100 3 70 -105 70 -105 5 37 c7 49
2 63 -22 63 -17 0 -19 6 -16 53 l3 52 105 0 105 0 3 -52 c3 -48 1 -53 -17 -53
-20 0 -21 -7 -23 -187 l-3 -188 -80 -3 -80 -2 -69 105 c-38 58 -72 105 -77
105 -4 0 -8 -22 -7 -49 0 -46 2 -50 26 -53 25 -3 27 -6 27 -53 l0 -50 -327 -3
-328 -2 0 55 c0 48 2 55 20 55 19 0 20 7 20 135 0 128 -1 135 -20 135 -17 0
-20 7 -20 48 0 27 3 52 7 56 4 3 97 5 207 4 l201 -3 3 -83z m716 58 c-1 -14
-5 -62 -9 -107 l-8 -83 -52 0 -52 0 -12 88 c-18 143 -23 133 62 130 70 -3 72
-4 71 -28z m1574 -26 c3 -46 1 -52 -19 -57 -21 -6 -22 -9 -17 -111 3 -64 10
-111 18 -120 15 -19 63 -21 78 -3 6 7 12 62 13 122 1 96 -1 110 -15 113 -14 3
-16 13 -14 55 l3 52 105 0 105 0 3 -48 c3 -41 0 -49 -16 -54 -18 -4 -20 -16
-24 -126 -3 -101 -7 -130 -25 -167 -48 -97 -170 -129 -281 -74 -43 21 -56 34
-78 80 -23 47 -27 72 -32 169 -4 88 -8 115 -18 115 -10 0 -14 14 -14 48 0 27
3 52 7 56 4 3 54 5 112 4 l106 -3 3 -51z m636 31 c20 -10 43 -30 51 -46 19
-36 19 -96 0 -133 -14 -26 -13 -30 10 -58 30 -35 34 -111 10 -158 -29 -56 -61
-65 -257 -68 l-178 -4 0 52 c0 43 3 53 20 57 19 5 20 14 20 139 0 127 -1 134
-20 134 -17 0 -20 7 -20 48 0 27 3 52 8 57 4 4 77 5 163 3 120 -4 164 -9 193
-23z"/>
<path d="M5205 4080 c-10 -17 11 -36 56 -49 49 -15 58 -9 38 27 -11 20 -24 28
-51 30 -20 2 -39 -2 -43 -8z"/>
<path d="M5150 3894 c1 -41 84 -71 109 -40 10 12 8 17 -7 25 -27 15 -102 25
-102 15z"/>
<path d="M6045 4080 c-4 -6 -1 -17 5 -25 13 -15 88 -40 97 -32 6 7 -17 50 -31
59 -16 11 -64 9 -71 -2z"/>
<path d="M5990 3890 c0 -25 54 -54 86 -46 38 10 29 34 -18 46 -24 6 -49 13
-55 15 -8 3 -13 -4 -13 -15z"/>
<path d="M7027 4093 c-4 -3 -7 -22 -7 -40 0 -32 2 -34 28 -31 19 2 28 9 30 26
5 36 -30 67 -51 45z"/>
<path d="M7020 3874 l0 -45 37 3 c41 3 50 19 39 64 -5 20 -13 24 -41 24 -35 0
-35 0 -35 -46z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
